import * as React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import YoutubeApi from '../components/youtube-api';
import { CampaignLoader } from '@imprologic/simple-ads';

const adMetadataUrl = 'https://ads.imprologic.com/bitcoin/index.json';


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    );
  }

  const listItems = posts.map(
    post => {
      const title = post.frontmatter.title || post.fields.slug;

      return (
        <li key={post.fields.slug}>
          <article
            className="post-list-item"
            itemScope
            itemType="http://schema.org/Article"
          >
            <header>
              <h2>
                <Link to={post.fields.slug} itemProp="url">
                  <span itemProp="headline">{title}</span>
                </Link>
              </h2>
              <small>{post.frontmatter.date}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: post.frontmatter.description || post.excerpt,
                }}
                itemProp="description"
              />
            </section>
          </article>
        </li>
      );
    }
  );

  const ad1 = (
    <li key="ad1">
      <CampaignLoader metadataUrl={adMetadataUrl} zoomLevel={0.75} />
    </li>
  );
  listItems.splice(18, 0, ad1);
  listItems.splice(10, 0, ad1);
  listItems.splice(2, 0, ad1);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <YoutubeApi />
      <Bio />
      <ol style={{ listStyle: `none` }}>
        {listItems}
      </ol>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
